@font-face {
  font-family: 'MarkPro-Bold SKY';
  src: url('/fonts/MarkPro-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'MarkPro-Book SKY';
  src: url('/fonts/MarkPro-Book.ttf') format('truetype');
}
@font-face {
  font-family: 'MarkPro-Black SKY';
  src: url('/fonts/MarkPro-Black.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'MarkPro-Book SKY', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Fresh chat overrides */
#fc_frame,
#fc_frame.fc-widget-small {
  bottom: 10% !important;
  right: 6px !important;
}

@media (min-width: 768px) {
  #fc_frame,
  #fc_frame.fc-widget-small {
    bottom: 8px !important;
    right: 6px !important;
  }
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: linear-gradient(90deg, #283185 0%, #9000D4 100%);
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
    transform: scale(1.0);
  }
}

.btn-container{
  margin-top: 1.5rem;
  margin-bottom: 0.65rem;
}
.getstarted-quiz{
  max-width: 170px;
}
.details-quiz{
  max-width: 120px;
}
.width-fit{
  width:fit-content;
}
@media (max-width: 768px) {
  .width-fit{
    width:75% 
  }
}
.custom-carousel-bright-blue .slick-dots {
bottom: -40px;
}
.custom-carousel-bright-blue .slick-dots li{
  width: 6px;
}
.custom-carousel-bright-blue .slick-dots li.slick-active button:before{
color: #0057FF;
font-size: 20px;
width:22px;
height:22px;
opacity: 1;
}
@media (max-width: 768px) {

  .custom-carousel-bright-blue .slick-slide.slick-active >div>div {
    display:flex !important;
    align-items: center;
    flex-direction: column;
    justify-content: center;

     }

}

.height-inherit{
  height: inherit;
}
.terms-container-style{
  margin-top: 60px;
max-width:1500px;
}
.terms-font-style{
  font-size: 12px;
  line-height: 15.21px;
  text-align: center;

}
.w-310{
  width:310px;
 }
 .w-full-q{
  width:100%;
 }
 @media (min-width: 768px) and (max-width: 1024px) {
   .w-310{
     width:310px !important;
     margin-right:24px !important;
     
    }
    .w-full-q{
     width:90%;
    }
    .custom-carousel-bright-blue .slick-list{
      padding-top:30px;
      overflow-x: hidden !important;
    }
    .custom-carousel-bright-blue{
      padding-left: 25px;    
    }
    .custom-carousel-bright-blue .slick-arrow.slick-prev:before, .custom-carousel-bright-blue  .slick-arrow.slick-next:before {
      content: none;
    }
    .custom-carousel-bright-blue .slick-disabled {
      opacity: .1;
    }
 
 }
 .pb-24px{
   padding-bottom: 29.75px;
 }
 .min-h-102{
  min-height: 102px;
 }
 .h-24-9{
  height:24.29px
 }
.lh-18{
  line-height: 18px;
}
.lh-16{
  line-height: 16px;
}
